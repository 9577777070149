import React from "react";
import { useInterval } from "./interval.hook";
import { Monitor, MonitorEvent, RequestEvent } from "./types";
import { timeDiffInSeconds } from "./util";

type MonitorContainerProps = {
  monitor: Monitor;
  onSuccess: (monitor: Monitor, event: MonitorEvent) => void;
  onThresholdException: (monitor: Monitor, event: MonitorEvent) => void;
  onError: (monitor: Monitor, event: MonitorEvent) => void;
};

export const MonitorContainer = ({
  monitor,
  onError,
  onThresholdException,
  onSuccess,
}: MonitorContainerProps) => {
  const [monitorEvents, setMonitorEvents] = React.useState<MonitorEvent[]>([]);
  const appendMonitorEvent = React.useCallback(
    (newEvent: MonitorEvent) => {
      setMonitorEvents(monitorEvents.concat([newEvent]));

      if (newEvent.isError || newEvent.isOverThreshold) {
        if (newEvent.isError) {
          onError(monitor, newEvent);
        }
        if (newEvent.isOverThreshold) {
          onThresholdException(monitor, newEvent);
        }
      } else {
        onSuccess(monitor, newEvent);
      }
    },
    [
      monitorEvents,
      setMonitorEvents,
      monitor,
      onError,
      onSuccess,
      onThresholdException,
    ]
  );
  return (
    <MonitorSummary
      monitor={monitor}
      events={monitorEvents}
      onEvent={appendMonitorEvent}
    />
  );
};

type MonitorSummaryProps = {
  monitor: Monitor;
  events: MonitorEvent[];
  onEvent: (e: MonitorEvent) => void;
};

const errorsAndLatestFive = (
  e: MonitorEvent,
  idx: number,
  list: MonitorEvent[]
) => {
  return e.isError || e.isOverThreshold || idx >= list.length - 5;
};

const MonitorSummary = ({ monitor, events, onEvent }: MonitorSummaryProps) => {
  const { thresholdInSeconds, intervalInSeconds, url } = monitor;
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const intervalId = useInterval(() => {
    const start = Date.now();
    let end;
    const options = monitor.body
      ? {
          method: "POST",
          body: monitor.body,
          headers: {
            "Content-Type": "application/json",
          },
        }
      : { method: "GET" };
    fetch(url, options)
      .then((response) => {
        return { response: JSON.stringify(response.json()), error: null };
      })
      .catch((e) => {
        return { response: null, error: e.message || JSON.stringify(e) };
      })
      .then((event: RequestEvent) => {
        end = Date.now();
        const diffInMilli = end - start;
        const isOverThreshold = diffInMilli > thresholdInSeconds * 1000;
        const newMonitorEvent = {
          start,
          end,
          isError: !!event.error,
          isOverThreshold,
          message: event.response || event.error,
          duration: timeDiffInSeconds(start, end),
        };
        onEvent(newMonitorEvent);
      });
  }, intervalInSeconds * 1000);
  return (
    <ul>
      Interval for <strong>{monitor.url}</strong> running every{" "}
      <em>{monitor.intervalInSeconds} sec</em> with a threshold of{" "}
      <em>{monitor.thresholdInSeconds} sec.</em>
      <div>
        <button onClick={() => clearInterval(intervalId)}>Stop monitor</button>
        <button onClick={() => setShowAll(!showAll)}>
          {showAll ? "Hide" : "Show"} All Results
        </button>
      </div>
      {showAll && (
        <pre
          style={{
            maxHeight: "300px",
            overflow: "scroll",
            border: "2px solid cadetblue",
            backgroundColor: "aliceblue",
            padding: "1em",
          }}
        >
          {JSON.stringify({ monitor, events }, null, 4)}
        </pre>
      )}
      {events.filter(errorsAndLatestFive).map((monEv) => {
        return (
          <li
            key={monEv.start}
            style={{
              margin: "2em 0",
              padding: "0.5em",
              borderLeft:
                monEv.isError || monEv.isOverThreshold
                  ? "4px solid red"
                  : "none",
            }}
          >
            {monEv.isError && (
              <div>
                <strong>Is Error</strong>
              </div>
            )}

            {monEv.isOverThreshold && (
              <div>
                <strong>Request was over threshold</strong>
              </div>
            )}

            <div>
              <strong>Timestamp:</strong> {new Date(monEv.start).toString()}
            </div>
            <div>
              <strong>Duration:</strong> {monEv.end - monEv.start}
            </div>
            <div>
              <strong>Message:</strong> {monEv.message}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
