import React from "react";
import "./App.css";
import { Monitor, MonitorEvent } from "./types";
import { MonitorContainer } from "./Monitor";

const form = {
  url: {
    label: "URL",
    inputProps: {
      type: "text",
      placeholder: "https:google.com",
      required: true,
      defaultValue: "",
      size: 100,
    },
  },
  name: {
    label: "Monitor Name",
    inputProps: {
      type: "text",
      placeholder: "some_unique_name_for_machine",
      required: true,
      size: 150,
    },
  },
  intervalInSeconds: {
    inputProps: {
      type: "number",
      defaultValue: 5,
      min: 1,
      step: 1,
      required: true,
      size: 5,
    },
    label: (
      <>
        Interval <small>(in seconds)</small>
      </>
    ),
  },
  thresholdInSeconds: {
    inputProps: {
      type: "number",
      defaultValue: 5,
      min: 1,
      step: 1,
      required: true,
      size: 5,
    },
    label: (
      <>
        Response Time Threshold <small>(in seconds)</small>
      </>
    ),
  },
  body: {
    inputProps: {
      type: "textarea",
      defaultValue: "",
      rows: 10,
      cols: 40,
    },
    label: <>Request Body:</>,
  },
};

type InputName = keyof typeof form;
const inputNames = Object.keys(form) as InputName[];

type AppProps = {
  logger: { info: (x: string) => void; error: (x: Error) => void };
};
function App({ logger }: AppProps) {
  const [monitors, setMonitors] = React.useState<Monitor[]>([]);
  const onSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const url = formData.get("url") as string;
      const name = formData.get("name") as string;
      const intervalInSeconds = parseInt(
        formData.get("intervalInSeconds") as string
      );
      const thresholdInSeconds = parseInt(
        formData.get("thresholdInSeconds") as string
      );
      const body = (formData.get("body") as string) || null;
      const monitorId = Date.now();
      setMonitors([
        ...monitors,
        {
          id: monitorId,
          name,
          url,
          intervalInSeconds,
          thresholdInSeconds,
          body,
        },
      ]);
    },
    [monitors, setMonitors]
  );
  const onSuccess = (monitor: Monitor, event: MonitorEvent) => {
    const msg = `Monitor ${monitor.name} at "${monitor.url}" - Succesful Request`;
    logger.info(msg);
  };
  const onError = (monitor: Monitor, event: MonitorEvent) => {
    const msg = `Monitor ${monitor.name} at "${monitor.url}" - Exception recieved: ${event.message}`;
    logger.error(new Error(msg));
  };
  const onThresholdException = (monitor: Monitor, event: MonitorEvent) => {
    const msg = `Monitor ${monitor.name} at "${monitor.url}" - Call exceeded defined threshold (${monitor.thresholdInSeconds} seconds)`;
    logger.error(new Error(msg));
  };
  return (
    <div className="App">
      <h1>Monitor Network Connections</h1>
      <form onSubmit={onSubmit}>
        <fieldset
          style={{
            display: "flex",
            flexFlow: "column nowrap",
          }}
        >
          <legend>Create URL Monitor</legend>

          {inputNames.map((key) => {
            const { label, inputProps } = form[key];

            return (
              <label key={key}>
                {label}
                {inputProps.type === "textarea" ? (
                  <textarea name={key} {...inputProps} />
                ) : (
                  <input name={key} {...inputProps} />
                )}
              </label>
            );
          })}
          <button type="submit">Begin New Monitor</button>
        </fieldset>
      </form>
      <h3>Monitors</h3>
      <ul style={{ display: "flex", flexFlow: "row wrap" }}>
        {monitors.map((m) => {
          return (
            <li
              key={m.id}
              style={{ flexBasis: "33%", padding: "1em", maxWidth: "50%" }}
            >
              <MonitorContainer
                monitor={m}
                onSuccess={onSuccess}
                onError={onError}
                onThresholdException={onThresholdException}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default App;
